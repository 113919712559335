import React from 'react';
import { PrimaryButton } from '../../button/primary-button';
import styles from './cookie-controls.module.scss';

export const CookieButton = ({ children, onClick, variant = 'default', className }) => {
  return (
    <PrimaryButton onClick={onClick} variant={variant} type="button" className={styles.cookieButton}>
      {children}
    </PrimaryButton>
  );
};
