import React from 'react';
import styles from './button.module.scss';
import clsx from 'clsx';

export const PhoneButton = ({ phone, children, className, ...other }) => {
  return (
    <a href={`tel:${phone}`} {...other} className={clsx(styles.button, className)}>
      {children}
    </a>
  );
};
