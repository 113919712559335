import React from 'react';
import clsx from 'clsx';
import { InternalLinkButton } from './internal-link-button';
import { PhoneButton } from './phone-button';
import IconPhone from '../../images/icons/icon-phone.svg';
import IconMail from '../../images/icons/icon-mail.svg';
import styles from './cta-button.module.scss';

const CtaButton = ({ className, cta, useIconOnMobile = false }) => {
  return (
    <div className={clsx(styles.cta, className, useIconOnMobile && styles.isMobileCta)}>
      <InternalLinkButton
        to={cta.to}
        className={clsx(styles.button, styles.requestButton, useIconOnMobile && styles.mobileButton)}
        color="highlight"
      >
        <img src={IconMail} className={styles.mailIcon} alt="E-Mail" />
        <span>{cta.label}</span>
      </InternalLinkButton>
      <PhoneButton
        className={clsx(styles.button, styles.phoneButton, useIconOnMobile && styles.mobileButton)}
        phone="+494088215940"
      >
        <img src={IconPhone} className={styles.phoneIcon} alt="Telefon" />
        <span>+49 40 88215940</span>
      </PhoneButton>
    </div>
  );
};

export default CtaButton;
