import React from 'react';
import clsx from 'clsx';
import styles from './container.module.scss';

const Container = ({ className, children, variant = 'section', ...other }) => {
  const Component = variant;
  return (
    <Component className={clsx(styles.container, className)} {...other}>
      {children}
    </Component>
  );
};

export default Container;
