import React from 'react';
import clsx from 'clsx';
import { Link } from 'gatsby';
import styles from './button.module.scss';

export const InternalLinkButton = ({ to, children, color = 'primary', className, ...other }) => {
  const colorClass = color === 'highlight' ? 'highlight' : 'primary';
  return (
    <Link to={to} {...other} className={clsx(styles.button, className, styles[colorClass])}>
      {children}
    </Link>
  );
};
