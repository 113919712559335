import React, { useState, useMemo, useContext } from 'react';
import { CookieButton } from '../cookies-controls/cookie-button';
import { CookieCategory } from '../cookie-category/cookie-category';
import styles from './cookies-settings.module.scss';
import { CookiesContext } from '../cookies-context/cookies-context';

export const CookiesSettings = ({ onAcceptAll, onSave }) => {
  const [activeTab, setActiveTab] = useState(0);

  const { acceptedCookies, setAcceptedCookies } = useContext(CookiesContext);

  const tabs = useMemo(
    () => [
      {
        title: 'Einstellungen',
        content: (
          <div className={styles.settingsContent}>
            <p>Wir setzen Cookies für folgende Zwecke ein:</p>
            <CookieCategory
              title="Notwendig"
              switchDisabled
              onAccept={(v) =>
                setAcceptedCookies({
                  ...acceptedCookies,
                  necessary: v,
                })
              }
              isAccepted={acceptedCookies.necessary}
              previewContent={
                <p>
                  Diese Cookies sind für den Betrieb der Seite unbedingt notwendig und ermöglichen beispielsweise
                  sicherheitsrelevante Funktionalitäten. Außerdem können wir mit dieser Art von Cookies ebenfalls
                  erkennen, ob Sie in Ihrem Profil eingeloggt bleiben möchten, um Ihnen unsere Dienste bei einem
                  erneuten Besuch unserer Seite schneller zur Verfügung zu stellen.
                </p>
              }
              moreContent={
                <ul>
                  <li>Evelan Cookie Banner</li>
                </ul>
              }
            />
            <CookieCategory
              title="Performance"
              onAccept={(v) =>
                setAcceptedCookies({
                  ...acceptedCookies,
                  performance: v,
                })
              }
              isAccepted={acceptedCookies.performance}
              previewContent={
                <p>
                  Diese Cookies sind für Bereitstellung der statistischen Informationen über unsere Website. Sie werden
                  zur Leistungsmessung und -verbesserung verwendet.
                </p>
              }
              moreContent={
                <ul>
                  <li>Google Analytics</li>
                  <li>Hotjar</li>
                </ul>
              }
            />
            <CookieCategory
              title="Marketing"
              onAccept={(v) =>
                setAcceptedCookies({
                  ...acceptedCookies,
                  advertising: v,
                })
              }
              isAccepted={acceptedCookies.advertising}
              previewContent={
                <p>
                  Diese Cookies werden genutzt, um Ihnen personalisierte Inhalte, passend zu Ihren Interessen
                  anzuzeigen. Somit können wir Ihnen Angebote präsentieren, die für Sie besonders relevant sind.
                </p>
              }
              moreContent={
                <ul>
                  <li>Facebook Pixel</li>
                </ul>
              }
            />
          </div>
        ),
      },
    ],
    [acceptedCookies, setAcceptedCookies]
  );

  return (
    <div className={styles.settingsContainer}>
      <h4>Cookie-Einstellungen</h4>
      <div className={styles.tabsContainer}>
        {tabs.map(({ title }, i) => (
          <CookieButton key={title} variant={activeTab === i ? 'primary' : 'default'} onClick={() => setActiveTab(i)}>
            {title}
          </CookieButton>
        ))}
      </div>
      <div className={styles.tabsContent}>
        {tabs.map(({ title, content }, i) => {
          if (activeTab === i) {
            return <div key={`${title}-content-key`}>{content}</div>;
          }
          return null;
        })}
      </div>
      <div className={styles.actions}>
        <CookieButton variant="text" onClick={onSave}>
          Auswahl speichern
        </CookieButton>
        <CookieButton
          onClick={() => {
            if (onAcceptAll) {
              onAcceptAll();
            }
          }}
          variant="primary"
        >
          Alle akzeptieren
        </CookieButton>
      </div>
    </div>
  );
};
