import React from 'react';
import clsx from 'clsx';
import styles from './button.module.scss';

export function PrimaryButton({ children, onClick, className = '', variant, ...other }) {
  return (
    <button
      className={clsx(
        styles.button,
        variant === 'primary' && styles.submitButton,
        variant === 'secondary' && styles.secondaryButton,
        variant === 'text' && styles.textButton,
        className
      )}
      onClick={onClick}
      {...other}
    >
      {children}
    </button>
  );
}
