import Cookies from 'js-cookie';

export const CATEGORIES = {
  PERFORMANCE: 'PERFORMANCE',
  ADVERTISMENTS: 'ADVERTISMENTS',
};

export const COOKIE_KEYS = {
  [CATEGORIES.PERFORMANCE]: 'gdpr-analytics-enabled',
  [CATEGORIES.ADVERTISMENTS]: 'gdpr-marketing-enabled',
};

export default {
  isInitial: () => {
    let isAllNull = true;
    Object.values(CATEGORIES).forEach((category) => {
      if (Cookies.get(COOKIE_KEYS[category]) !== undefined && isAllNull === true) {
        isAllNull = false;
      }
    });
    return isAllNull;
  },
  get: (category) => {
    return Cookies.get(COOKIE_KEYS[category]) === 'true';
  },
  update: (category, newValue) => {
    switch (category) {
      case CATEGORIES.PERFORMANCE: {
        Cookies.set(COOKIE_KEYS[category], newValue, { expires: 365, sameSite: 'strict', secure: true });
        if (!window.trackHotjar) {
          // eslint-disable-next-line no-console
          console.error('trackHotjar is not defined in window');
        }
        if (window.trackHotjar && newValue === true) {
          window.trackHotjar();
        }

        if (typeof window.trackGoogleAnalytics !== 'function') {
          // eslint-disable-next-line no-console
          console.error('trackGoogleAnalytics is not defined in window');
        } else if (newValue === true) {
          window.trackGoogleAnalytics();
        }
        break;
      }
      case CATEGORIES.ADVERTISMENTS: {
        Cookies.set(COOKIE_KEYS[category], newValue, { expires: 365, sameSite: 'strict', secure: true });
        if (window.trackFbPixel && newValue === true) {
          window.trackFbPixel();
        }
        break;
      }
      default:
        break;
    }
  },
};
