import React, { useState, useEffect, useCallback, useContext } from 'react';
import { animated as a, useSpring, config } from 'react-spring';
import useMeasure from 'react-use-measure';
import { ResizeObserver } from '@juggle/resize-observer';
import clsx from 'clsx';
import { CookiesPrompt } from './cookies-prompt/cookies-prompt';
import { CookiesSettings } from './cookies-settings/cookies-settings';
import { CookiesContext, CookiesShowContext } from './cookies-context/cookies-context';
import CookieFunctions, { CATEGORIES } from '../../../cookie-functions';
import styles from './cookies.module.scss';

const hideConfig = {
  ...config.default,
  clamp: true,
};

function saveSettings(acceptedCookies) {
  CookieFunctions.update(CATEGORIES.PERFORMANCE, acceptedCookies.performance);
  CookieFunctions.update(CATEGORIES.ADVERTISMENTS, acceptedCookies.advertising);
}

export const CookiesSnackbar = () => {
  // const [show, setShow] = useState(false);
  const { showCookies, setShowCookies } = useContext(CookiesShowContext);
  const [configure, setConfigure] = useState(true);

  const [acceptedCookies, setAcceptedCookies] = useState({
    necessary: true,
    performance: CookieFunctions.get(CATEGORIES.PERFORMANCE),
    advertising: CookieFunctions.get(CATEGORIES.ADVERTISMENTS),
  });

  const [ref, { height }] = useMeasure({ polyfill: ResizeObserver });

  const showSpring = useSpring({
    transform: `translateY(${showCookies ? 0 : 120}%)`,
  });

  const heightSpring = useSpring({
    height,
  });

  const promptHideSpring = useSpring({
    transform: `translateY(${!configure ? 0 : -200}px)`,
    opacity: !configure ? 1 : 0,
    pointerEvents: !configure ? 'all' : 'none',
    config: {
      ...hideConfig,
      tension: !configure ? config.default.tension : 500,
    },
  });

  const settingsHideSpring = useSpring({
    transform: `translateY(${configure ? 0 : 200}px)`,
    opacity: configure ? 1 : 0,
    pointerEvents: configure ? 'all' : 'none',
    config: {
      ...hideConfig,
      tension: configure ? config.default.tension : 500,
    },
  });

  useEffect(() => {
    if (CookieFunctions.isInitial()) {
      setConfigure(false);
      setTimeout(() => {
        setShowCookies(true);
      }, 1000);
    }
  }, [setShowCookies]);

  const acceptAll = useCallback(() => {
    const newAcceptedCookies = {
      necessary: true,
      performance: true,
      advertising: true,
    };
    setAcceptedCookies(newAcceptedCookies);
    saveSettings(newAcceptedCookies);
    setShowCookies(false);
  }, [setAcceptedCookies, setShowCookies]);

  const acceptOnlyNecessary = useCallback(() => {
    const newAcceptedCookies = {
      necessary: true,
      performance: false,
      advertising: false,
    };
    setAcceptedCookies(newAcceptedCookies);
    saveSettings(newAcceptedCookies);
    setShowCookies(false);
  }, [setAcceptedCookies, setShowCookies]);

  return (
    <CookiesContext.Provider value={{ acceptedCookies, setAcceptedCookies }}>
      <a.div className={clsx(styles.cookiesContainer)} style={showSpring}>
        <a.div className={styles.cookies} style={heightSpring}>
          <a.div className={styles.hider} style={promptHideSpring}>
            <div ref={!configure ? ref : null}>
              <CookiesPrompt
                onAcceptAll={acceptAll}
                onConfigure={() => setConfigure(true)}
                onRejectAll={acceptOnlyNecessary}
              />
            </div>
          </a.div>
          <a.div className={styles.hider} style={settingsHideSpring}>
            <div ref={configure ? ref : null}>
              <CookiesSettings
                onSave={() => {
                  saveSettings(acceptedCookies);
                  setShowCookies(false);
                }}
                onAcceptAll={acceptAll}
              />
            </div>
          </a.div>
        </a.div>
      </a.div>
    </CookiesContext.Provider>
  );
};
